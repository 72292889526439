// 封装Axios

// 1.引入Axios
import axios from "axios";
import Qs from "qs"; //引入qs  时axios的自带模块

export function request(config) {
  // 创建实例
  const instance = new axios.create({
    // baseURL: 'https://recoverwww.joiningyun.com',
    baseURL: "https://api.quotemycars.com.au",
  });

  //  设置拦截器
  instance.interceptors.request.use(
    (config) => {
      // 添加token
      // config.headers.Authorization = sessionStorage.getItem('token')
      config.data = Qs.stringify(config.data);
      config.headers = {
        Accept: "*/*",
        "Content-type": "application/x-www-form-urlencoded", //formdata格式传参
        "X-Requested-With": "XMLHttpRequest",
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance(config);
}
